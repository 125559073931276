













































































































































































import {
  computed,
  defineComponent,
  useRoute,
  useFetch,
  ref,
  useMeta,
  onMounted, toRef,
} from '@nuxtjs/composition-api';
import { getMetaInfo, getStoryMeta, getUrlMeta } from '~/helpers/getMetaInfo';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import SfCarousel from '~/components/StorefrontUi/SfCarousel.vue';
import BlogArticleTile from '~/modules/storyblok/components/Blog/BlogArticleTile.vue';
import {
  useUiHelpers,
} from '~/composables';
import BackButton from '~/components/BackButton.vue';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import getFormattedDate from '~/helpers/getFormattedDate';
import { useUser } from '~/modules/customer/composables/useUser';
import useStoryblokView from '~/modules/storyblok/composables/useStoryblokView';
import { useJsonLd } from '~/modules/jsonLD';

export default defineComponent({
  name: 'BlogArticle',
  components: {
    BackButton,
    SfImage,
    SfCarousel,
    BlogArticleTile,
  },
  layout: 'basic',
  props: {
    currentStory: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const currentStory = toRef(props, 'currentStory');
    const route = useRoute();
    const path = route.value;
    const { addTags } = useCache();
    const { isAuthenticated } = useUser();
    const articles = ref([]);
    const { getSelectedArticlesByUUIDs } = useStoryblokView();
    const uiHelpers = useUiHelpers();
    const { getArticleSchema } = useJsonLd();

    const metaInfo = () => {
      const urlMeta = getUrlMeta(uiHelpers.resolvePath());
      return getStoryMeta({ data: currentStory.value, baseData: urlMeta });
    };
    useMeta(() => getMetaInfo({ page: metaInfo() }));

    const shareOpenNewTab = (url:string) => {
      if (process.client) {
        const localPath = window.location.origin + path.fullPath;
        window.open(url + localPath, '_blank', 'noreferrer');
      }
    };

    const shareFn = () => {
      global.navigator.share({
        url: path.fullPath || '',
      });
    };
    const { fetch } = useFetch(async () => {
      if (!isAuthenticated.value) {
        addTags([{ prefix: CacheTagPrefix.View, value: currentStory.value?.full_slug }]);
      }

      const articlesUUIDs = currentStory.value?.content.readMore;
      if (articlesUUIDs.length <= 0) return;
      const { stories } = await getSelectedArticlesByUUIDs({
        uuids: articlesUUIDs,
        params: {
          sort_by: 'created_at:desc',
          per_page: 6,
        },
      });
      articles.value = stories;
    });
    fetch();

    onMounted(() => {
      if (currentStory.value?.id) {
        useStoryblokBridge(currentStory.value.id, (evStory) => { (currentStory.value = evStory); }, { preventClicks: true });
      }
    });

    const articleSchema = getArticleSchema({
      headline: currentStory.value?.content?.title,
      image: currentStory.value?.content?.preview?.cached_url,
      datePublished: currentStory.value?.first_published_at || currentStory.value?.created_at,
      author: currentStory.value?.content?.author,
    });

    const getArticleBackground = computed(() => {
      if (!currentStory.value?.content?.heroBackgraund?.filename) {
        switch (currentStory.value?.content.mainTag) {
          case 'WARZYWNICTWO':
            return 'https://a.storyblok.com/f/223062/1920x400/acd44409b9/no-bg-image-2.svg';
          case 'ROLNICTWO':
            return 'https://a.storyblok.com/f/223062/1920x400/376e041c03/no-bg-image.svg';
          case 'SADOWNICTWO':
            return 'https://a.storyblok.com/f/223062/1920x400/98de71b363/no-bg-image-1.svg';
          case 'AKTUALNOŚCI':
            return 'https://a.storyblok.com/f/223062/1920x400/3ea3e9a4fb/no-bg-image-3.svg';
          default:
            return '';
        }
      }
      return currentStory.value?.content.heroBackgraund.filename;
    });
    return {
      articles,
      SfImage,
      shareFn,
      addBasePath,
      shareOpenNewTab,
      articleSchema,
      getFormattedDate,
      author: computed(() => (currentStory.value?.content.author !== '' ? currentStory.value?.content.author : 'Brak autora')),
      bgImage: getArticleBackground,
      date: computed(() => (
        currentStory.value?.content?.wpPublishDate || currentStory.value?.first_published_at || currentStory.value?.created_at
      )),
      secondaryTag: computed(() => (currentStory.value?.content.secondaryTag !== '' ? currentStory.value?.content.secondaryTag : 'Blog Agrosimex')),
      description: computed(() => currentStory.value?.content.description),
    };
  },
  head: {},
});
